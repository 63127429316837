@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@700&family=Roboto&display=swap");

:root {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

#root {
  background-color: #242424;
  width: 100%;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  width: 100vw;
  overflow-x: hidden;
}

.content {
  border: 2px solid white;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

input,
textarea {
  font-family: inherit;
  font-size: inherit;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 10px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0 0 0 0;
  padding: 0;
}

svg text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
